import { useState } from "react";
import styles from "./styles.module.css";
import { ButtonFactory } from "@shared/ui/Button";
import { PlanItem } from "@features/plan";

const BONUSES = [
  {
    id: 1,
    duration: "12 months",
    price: "$5.99",
    percentageOff: 75,
    was: "$25.99/month",
  },
  {
    id: 2,
    duration: "3 months",
    price: "$9.99",
    percentageOff: 60,
    was: "$25.99/month",
  },
  {
    id: 3,
    duration: "1 months",
    price: "$12.99",
    percentageOff: 60,
    was: "$25.99/month",
  },
];

const Bonuses = () => {
  const [activeBonus, setActiveBonus] = useState<number>(1);
  const [isPaymentForOneMonth, setPaymentForOneMonth] = useState<boolean>(true);

  const payWithCredit = () => {
    console.log(
      "payWithCredit bonus id ->",
      BONUSES.find((item) => item.id === activeBonus)
    );
  };

  const payWithCrypto = () => {
    console.log(
      "payWithCrypto bonus id ->",
      BONUSES.find((item) => item.id === activeBonus)
    );
  };

  const setBonus = (id: number) => {
    const oneMonthId = 3;
    id === oneMonthId
      ? setPaymentForOneMonth(false)
      : setPaymentForOneMonth(true);

    setActiveBonus(id);
  };

  return (
    <div className={styles.plan_bonuses}>
      {BONUSES.map((planItem) => (
        <PlanItem
          key={planItem.id}
          plan={planItem}
          isActive={planItem.id === activeBonus}
          setActiveBonus={(id: number) => setBonus(id)}
        />
      ))}
      <h6 className={styles.plan_no_adult}>
        No adult transaction in your bank statement
      </h6>
      <div className={styles.plan_actions}>
        <ButtonFactory
          variant="danger"
          label="Pay with Credit / Debit Card"
          type="button"
          onClick={payWithCredit}
        />

        {isPaymentForOneMonth && (
          <ButtonFactory
            variant="primary"
            label="Pay with crypto"
            type="button"
            onClick={payWithCrypto}
          />
        )}
      </div>
      <h6 className={styles.plan_details}>Annual Payment Billed As $71.88</h6>
      <h6 className={styles.plan_details}>Cancel Anytime</h6>
    </div>
  );
};

export default Bonuses;
