import styles from './styles.module.css';
import { ROUTES } from "@shared/interfaces";
import { Link } from "react-router-dom";

const TextSignIn = () => {
  return (
    <div className={styles.text_signup_container}>
      <h5>Already have an account yet?</h5>
      <Link to={ROUTES.LOGIN}>
        Sign in
      </Link>
    </div>
  )
}

export default TextSignIn;