import styles from "./styles.module.css";

import Token from "@assets/token.svg";

interface IProps {
  plan: any;
  isActive: boolean;
  setActiveBonus: (id: number) => void;
}

const PlanItem: React.FC<IProps> = ({
  plan,
  isActive,
  setActiveBonus,
}: IProps) => {
  const classes = [styles.plan_container];

  if (isActive) {
    classes.push(styles.plan_active);
  }

  return (
    <div className={classes.join(" ")} onClick={() => setActiveBonus(plan.id)}>
      <div className={styles.plan_desc}>
        <h5>
          {plan.duration} <span>{plan.percentageOff}% off</span>
        </h5>
        <h6>Was {plan.was}</h6>
      </div>
      <div className={styles.plan_price}>
        <h3>
          {plan.price} <span>/</span>
        </h3>
        <span>month</span>
      </div>
    </div>
  );
};

export default PlanItem;
