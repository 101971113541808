import { IFBUser, IUserState } from "./user.types";
import { axiosInstance } from "@shared/utils/configs/axiosInstance";
import { create } from "zustand";

export const useUserStore = create<IUserState>((set, get) => {
  return {
    user: {
      //initialising | IUser | null
      id: "initialising",
      email: null,
      first_name: "",
      last_name: "",
      gender_choice: "",
      plan: "",
    },
    setUser: async (userData: any) => {
      if (userData?.accessToken) {
        localStorage.setItem("_accessToken", userData.accessToken);
        const startedChoosing = localStorage.getItem("isStartedChoosing");

        const { data } = await axiosInstance.get<IFBUser>("/api/user", {
          headers: {
            Authorization: userData.accessToken,
          },
        });

        if (startedChoosing) {
          await get().setUserChoice(startedChoosing);
          data.gender_choice = startedChoosing;
        }

        set({ user: { ...data } });
      } else {
        if (!userData) {
          set({ user: { id: null } });
        }
      }
    },
    setUserChoice: async (choice: string) => {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const body = {
        gender_choice: choice,
      };

      try {
        await axiosInstance.patch<IFBUser>("/api/user", body, axiosConfig);
        const currentUser = get().user;

        set({ user: { ...currentUser, gender_choice: choice } });
      } catch (e) {
        console.log(e);
      }
    },
  };
});
