import styles from "./styles.module.css";
import { LinkFactory } from "@shared/ui/Link";
import { ROUTES } from "@shared/interfaces";
import CloseIcon from "@assets/close.svg";
import UpdatePlanImg from "@assets/update-plan.jpg";

interface IProps {
  onClose: () => void;
}

const UpdatePlan = ({ onClose }: IProps) => {
  return (
    <div className={styles.update_plan_base}>
      <div className={styles.update_plan_container}>
        <div className={styles.update_plan_bg}>
          <img src={UpdatePlanImg} alt="update_paln"/>
        </div>
        <div className={styles.update_plan_content}>
          <img
            src={CloseIcon}
            className={styles.update_plan_close_button}
            alt="close"
            onClick={onClose}
          />
          <h2>Upgrade To Unlock</h2>
          <span className={styles.update_plan_suptitle}>
            Unlimited Messages
          </span>
          <ul>
            <li>Create Your Own AI Girlfriend(S)</li>
            <li>Unlimited Text Messages</li>
            <li>Remove Image Blur</li>
            <li>Get 100 FREE Tokens / Month</li>
            <li>And More...</li>
          </ul>

          <LinkFactory
            label="Upgrade to Premium"
            type="danger"
            path={ROUTES.PLANS}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdatePlan;
