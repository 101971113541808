import styles from './styles.module.css';
import { useState } from 'react';

import { ButtonFactory } from "@shared/ui/Button";
import { InputItem } from '@shared/ui/Inputs';
import { useUserAuth } from '@shared/model';
import useForm from '@shared/model/hooks/useForm';
import { onResetFormValid } from '../model';
import { ROUTES } from '@shared/interfaces';
import { Link } from 'react-router-dom';

import AuthImg from "@assets/auth_img.jpg";
import Arrow from '@assets/arrow_m.svg';
import { IFormEmailState } from '@widgets/auth/loginForm';

const ForgotPasswordForm = () => {
  const [activeFormError, setActiveFormError] = useState<Record<string, string>>();
  const { restorePasswordByEmail } = useUserAuth();

  const user: IFormEmailState = {
    email: '',
  };

  const { 
    formData, 
    onChangeInput, 
    onSubmitForm,
    resetFormData,
  } = useForm<IFormEmailState>(
    user,
    handlerBeforeSubmitForm
  );

  async function handlerBeforeSubmitForm() {
    const formErrors = onResetFormValid<IFormEmailState>(formData);
    const arrayOfErrors = Object.keys(formErrors);

    if (arrayOfErrors.length) {
      return setActiveFormError(formErrors);
    }

    setActiveFormError({});

    try{
      await restorePasswordByEmail(formData);
      resetFormData();
      alert("An email recovery link has been sent to the specified email address.");
    } catch(e: any){
      setActiveFormError({email: e.message});
    }
  }

  return (
    <div className={styles.form_reset_container}>
      <div>
        <img src={AuthImg} alt="auth_img"/>
      </div>
      <div className={styles.form_reset_wrapper}>
        <Link to={ROUTES.LOGIN} className={styles.linkBack}>
         <img src={Arrow} alt="back"/>
         <h6>Back</h6>
        </Link>
        <form onSubmit={onSubmitForm} className={styles.reset_form}>
          <h2 className={styles.form_reset_title}>Reset Password</h2>
          <h5 className={styles.form_reset_sub_title}>
            Enter your e-mail and we will send you <br/>the instructions to reset password
          </h5>
          <div className={styles.form_input_wrapper}>
            <InputItem
              type="email"
              name= "email"
              onChange={onChangeInput}
              value={formData?.email || ''}
              placeholder = "Email Address"
              error={activeFormError?.email}
            />
          </div>
          <div className={styles.error_box}>
            {activeFormError && 
            <span className={styles.form_error}>
              {activeFormError.email}
            </span>}
          </div>
          <ButtonFactory 
            type="submit"
            label="Reset Password" 
            variant='danger'
          />
        </form>
        <div className={styles.form_reset_subtext}>
          <h6>
          If we found an eligible account associated with that Email, we've sent password 
          reset instructions to the email address on the account.
          </h6>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordForm;