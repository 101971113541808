import { SubscriptionBanner } from '@widgets/subscription';
import styles from './styles.module.css';
import { AsideStructure } from '@widgets/aside_with_content';
import { HomeGallery } from '@widgets/home';


const HomePage: React.FC = () => {
  return (
    <div className={styles.home_container}>
      <AsideStructure>
        <div className={styles.home_content}>
         <SubscriptionBanner />
        </div>
        <div className={styles.home_gallery}>
          <HomeGallery />
        </div>

      </AsideStructure>
    </div>
  )
}

export default HomePage;