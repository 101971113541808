import { Link } from "react-router-dom";
import styles from "./styles.module.css";

import { IChatBarModel, IMessage, ROUTES } from "@shared/interfaces";

interface IProps {
  model: IChatBarModel;
  isActive: boolean;
  last_message: IMessage;
  isDesktop: boolean;
}

const ChatBarItem: React.FC<IProps> = ({
  model,
  isActive,
  last_message,
  isDesktop,
}: IProps) => {
  if (!model.id) {
    return <></>;
  }

  const backgroundStyles = {
    backgroundImage: "url(" + model.photo + ")",
  };

  const classes = [styles.item_container];

  if (isActive) {
    classes.push(styles.item_container_active);
  }

  if (!isDesktop) {
    classes.push(styles.item_container_adaptive);
  }

  const definedTime = () => {
    return (
      last_message?.send_at?.slice(11, 16) ||
      new Date().toLocaleString().replace(",", "").slice(11, 16)
    );
  };

  const parseText = (text: string) => {
    if (!text) {
      return text;
    }

    return text
      .replaceAll("<|eot_id|>", "")
      .replaceAll(
        "*smiles shyly*",
        String.fromCodePoint(parseInt("0x1F60A", 16))
      )
      .replaceAll("*smiles*", String.fromCodePoint(parseInt("0x1F600", 16)))
      .replaceAll("*blushes*", String.fromCodePoint(parseInt("0x1F970", 16)))
      .replaceAll("*waves*", String.fromCodePoint(parseInt("0x1F917", 16)))
      .replaceAll("*laughs*", String.fromCodePoint(parseInt("0x1F606", 16)))
      .replaceAll("*giggles*", String.fromCodePoint(parseInt(" 0x1F604", 16)))
      .replaceAll("*smirks*", String.fromCodePoint(parseInt(" 0x1F600", 16)))
      .replaceAll(
        "*smiles confidently*",
        String.fromCodePoint(parseInt("0x1F602", 16))
      );
  };

  return (
    <Link to={`${ROUTES.CHAT}/${model.id}`} className={classes.join(" ")}>
      <div style={backgroundStyles} className={styles.item_ava} />
      <div className={styles.item_about_wrapper}>
        <h6 className={styles.item_name}>{model.name}</h6>
        <h6 className={styles.item_text}>{parseText(last_message.text)}</h6>
        <time className={styles.item_time}>{definedTime()}</time>
      </div>
    </Link>
  );
};

export default ChatBarItem;
