import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { Typing } from "@widgets/chat";
import { Loader } from "@features/loader";
import MessageItemFactory from "@features/message/MessageItem.fabric";
import HowToUsePopup from "../howToUsePopup/HowToUsePopup";
import Burger from "@assets/burger.svg";
import { IChatItem, IMessage, ROUTES } from "@shared/interfaces";
import useForm from "@shared/model/hooks/useForm";
import { IFormMessageState } from "@widgets/auth/loginForm";
import onTypingFormValid from "@widgets/chat/ui/typing/typingValidationHook";
import { IUserState, useUserStore } from "@entities/user";
import { useLocation, useNavigate } from "react-router-dom";
import { Portal } from "@widgets/portal";
import { UpdatePlan } from "@widgets/subscription";
import { DropDownMenu } from "@shared/ui/DropDown";
import { Socket } from "@shared/libs/socket";
import Arrow from "@assets/arrow_nav.svg";

const MENU_LIST: any[] = [
  {
    title: "Restart Chat",
    value: "restart_chat",
  },
  {
    title: "Delete",
    value: "delete_chat",
    activeKey: true,
  },
];

interface IProps {
  setSteps?: (step: string) => void;
}

const ModelChat: React.FC<IProps> = ({ setSteps }: IProps) => {
  const isAuth = useUserStore((state: IUserState) => state.user.id);
  const isSubscriptionExist = useUserStore(
    (state: IUserState) => state.user.plan
  );
  
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const chat = useRef<null | HTMLDivElement>(null);
  const [showFirstMessage, setFirstMessage] = useState<boolean>(false);
  const [showHowToUse, setHowToUse] = useState<boolean>(false);
  const [showUpdatePlan, setUpdatePlan] = useState<boolean>(false);
  const [isDropDownShow, setDropDownShow] = useState(false);
  const [isSuggestionVisible, setVisibleSuggestion] = useState<boolean>(true);

  document.body.className = isDropDownShow ? "chat_restart_active" : "";

  const { model }: any = useModelStore(
    (state: IModelState) => state.modelActive
  );

  const messages = useModelStore(
    (state: IModelState) => state.messages
  );

  const modelsChats = useModelStore(
    (state: IModelState) => state.modelsChats
  );

  const disabledBtn = useModelStore(
    (state: IModelState) => state.disabledBtn
  );

  const { sendMessage, deleteChat, restartChat, setDisableBtn } = useModelStore(
    (state: IModelState) => state
  );

  const messageState: IFormMessageState = {
    message: "",
  };

  const { formData, onChangeInput, onSubmitForm, resetFormData } =
    useForm<IFormMessageState>(messageState, handlerBeforeSubmitForm);

  async function handlerBeforeSubmitForm() {
    const formErrors = onTypingFormValid<IFormMessageState>(formData);
    const arrayOfErrors = Object.keys(formErrors);

    if (arrayOfErrors.length) {
      return;
    }

    onSendMessage(formData.message);
    resetFormData();
    setDisableBtn(true);
  }

  const MAX_TYPING_TIME = 5;
  const MIN_TYPING_TIME = 2;
  const INTERVAL =
    Math.floor(Math.random() * (MAX_TYPING_TIME - MIN_TYPING_TIME + 1)) +
    MIN_TYPING_TIME;
  let TIMER: any;

  useEffect(() => {
    if (chat?.current) {
      chat.current.scrollIntoView(); //{ behavior: "smooth" }
    }

    if (messages && messages.length < 2) {
      setVisibleSuggestion(true);
    }

    return () => {
      setFirstMessage(false);
      clearTimeout(TIMER);
      setVisibleSuggestion(false);
      onChangeInput({ target: { name: "message", value: "" } });
    };
  }, [messages?.length]);

  if (!model?.id) {
    return <></>;
  }

  const renderMessages = () => {
    if (!messages || !messages?.length) {
      return <></>;
    }

    if (messages.length <= 1) {
      if (!showFirstMessage) {
        TIMER = setTimeout(() => {
          setFirstMessage(true);
          setDisableBtn(false);
          clearTimeout(TIMER);
        }, INTERVAL * 1000);

        return (
          <MessageItemFactory icon={<Loader />} variant="icon" type="model" />
        );
      }
    }

    return messages?.map((message: IMessage, index) => (
      <MessageItemFactory
        key={"message_" + index}
        variant="text"
        text={message.text}
        type={message.from}
        id={message.id}
      />
    ));
  };

  const onSendMessage = async (text: string) => {
    if (!isAuth) {
      return navigate(ROUTES.LOGIN);
    }

    if (!isSubscriptionExist && messages && messages?.length >= 10) {
      setUpdatePlan(true);
      return;
    }

    const activeChat = defineActiveChat();

    if (activeChat?.id) {
      const stringId = activeChat.id?.toString();
      return await sendMessage(stringId, text);
    }

    console.error("Active chat didn't defined");
  };

  const addSuggestions = () => {
    if (!isAuth) {
      localStorage.setItem("returnUrl", pathname);
     
      return navigate(ROUTES.LOGIN);
    }

    if (!isSubscriptionExist && messages && messages?.length >= 10) {
      setUpdatePlan(true);
      return;
    }

    if (!showFirstMessage) {
      return;
    }

    clearTimeout(TIMER);
    TIMER = null;

    const messageText = `${formData.message} ${
      model?.user_first_msg_help || ""
    }`;

    onChangeInput({ target: { name: "message", value: messageText } });
    setVisibleSuggestion(false);
  };

  const onCloseUpdatePlan = () => {
    setUpdatePlan(!showUpdatePlan);
  };

  const onClick = async ({ type }: { type: string }) => {
    const activeChat = defineActiveChat();

    if (!activeChat?.id) {
      console.error("Active chat didn't defined");
      return;
    }

    const stringId = activeChat.id?.toString();

    if (type === "restart_chat") {
      await restartChat(stringId);
    }

    if (type === "delete_chat") {
      await deleteChat(stringId);
    }

    setDropDownShow(false);
  };

  function defineActiveChat() {
    const activeChat =
      modelsChats &&
      modelsChats.find((chat: IChatItem) => chat.model.id === model.id);

    return activeChat;
  }

  const backgroundStyles = {
    backgroundImage: "url(" + model.photo + ")",
  };

  const goToChatBar = () => {
    navigate(ROUTES.CHAT);
    setSteps && setSteps("");
  };

  return (
    <div className={styles.model_chat_container}>
      {showUpdatePlan && (
        <Portal className="portal_home">
          <UpdatePlan onClose={onCloseUpdatePlan} />
        </Portal>
      )}
      {showHowToUse && <HowToUsePopup onClose={() => setHowToUse(false)} />}
      <div className={styles.model_chat_header}>
        <div className={styles.model_chat_header_info}>
          <img
            src={Arrow}
            className={styles.model_chat_header_arrow}
            alt="user_profile_arrow"
            onClick={() => goToChatBar()}
          />
          <div
            style={backgroundStyles}
            className={styles.header_info_ava}
            onClick={() => setSteps && setSteps("modelInfo")}
          />
          <h4 onClick={() => setSteps && setSteps("modelInfo")}>
            {model.name}
          </h4>
        </div>
        {isAuth && (
          <DropDownMenu
            active=""
            isVisible={isDropDownShow}
            menuList={MENU_LIST}
            callBack={onClick}
            onVisibleChange={() => setDropDownShow(!isDropDownShow)}
          >
            <div className={styles.header_burger}>
              <img
                src={Burger}
                alt="burger"
                onClick={() => setDropDownShow(!isDropDownShow)}
              />
            </div>
          </DropDownMenu>
        )}
      </div>
      <div className={styles.chat_body_container}>
        <>
          {renderMessages()}
          <div ref={chat} />
        </>
      </div>

      <div className={styles.model_chat_typing}>
        {isSuggestionVisible && (
          <div
            className={styles.model_chat_suggestion}
            onClick={addSuggestions}
          >
            <span>Suggestions</span>
            <h5>{model?.user_first_msg_help || ""}</h5>
          </div>
        )}
        <Typing
          formData={formData}
          onSubmitForm={onSubmitForm}
          onSendMessage={onSendMessage}
          onChangeInput={onChangeInput}
          disableSendBtn={disabledBtn}
          showHowToUse={() => setHowToUse(true)}
        />
      </div>
    </div>
  );
};

export default ModelChat;
