import styles from "./styles.module.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouterBuilder } from "./config/routes";
import { IUserState, useUserStore } from "@entities/user";
import { Portal } from "@widgets/portal";
import { Interested } from "@widgets/interested";
import { UserAuthContextProvider } from "@shared/model";
import { ErrorBoundary } from "react-error-boundary";
import { ROUTES } from "@shared/interfaces";

const App: React.FC = () => {
  const isAuth = useUserStore((state: IUserState) => state.user.id);

  let routes = new RouterBuilder().build();

  if (!isAuth) {
    routes = new RouterBuilder().addAuthRouters().build();
  }

  const router = createBrowserRouter(routes);

  const renderPortal = () => {
    const allowsRoutes: string[] = [
      ROUTES.GALLERY,
      ROUTES.HOME,
      ROUTES.CHAT,
      ROUTES.SUBSCRIBE,
    ];

    const isStartedChoosing = localStorage.getItem("isStartedChoosing");

    const pathName = window.location.pathname;
    const finedRouter = allowsRoutes.some((route) => route.includes(pathName));

    if (
      (!isStartedChoosing && finedRouter) ||
      (!isStartedChoosing && /^\/chat\/[0-9]$/.test(pathName) === true) ||
      (!isStartedChoosing && /^\/chat\/\$/.test(pathName) === true)
    ) {
      return (
        <Portal className="portal_home">
          <Interested />
        </Portal>
      );
    }
  };

  return (
    <>
      {renderPortal()}
      <RouterProvider router={router} />
    </>
  );
};

const AppWithProvider: React.FC = () => {
  return (
    <div className={styles.root__container}>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <UserAuthContextProvider>
          <App />
        </UserAuthContextProvider>
      </ErrorBoundary>
    </div>
  );
};

export default AppWithProvider;
