import { IUserState, useUserStore } from "@entities/user";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { Benefits, Bonuses, Event } from "@widgets/plans";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";

const SubscribePage = () => {
  const navigate = useNavigate();
  const isAuth = useUserStore((state: IUserState) => state.user.id);

  if (!isAuth) {
    setTimeout(() => navigate(ROUTES.REGISTER), 0);

    return <></>;
  }

  return (
    <div className={styles.subscribe_container}>
      <AsideStructure>
        <div className={styles.subscribe_content}>
          <h1 className={styles.subscribe_title}>Choose your plan</h1>
          <h4 className={styles.subscribe_suptitle}>
            100% anonymous. You can cancel anytime
          </h4>
          <div className={styles.subscribe_wrapper}>
            <Event />
            <Bonuses />
            <Benefits />
          </div>
        </div>
      </AsideStructure>
    </div>
  );
};

export default SubscribePage;
