import { ROUTES } from "@shared/interfaces";
import { LinkFactory } from "@shared/ui/Link";
import styles from "./styles.module.css";

import Crown from "@assets/crown.svg";
import { IUserState, useUserStore } from "@entities/user";

const SubscriptionBanner = () => {
  const user = useUserStore((state: IUserState) => state.user);

  return (
    <div className={styles.subscription_link_wrapper}>
      <LinkFactory
        label=""
        path={
          !user.id ? ROUTES.LOGIN : user.plan ? ROUTES.SUBSCRIBE : ROUTES.PLANS
        }
      >
        <div className={styles.subscription_badge_crown}>
          <img src={Crown} alt="" />
          <h5>First subscription</h5>
        </div>

        <div className={styles.subscription_badge_danger}>
          <h5>Up to 75% off</h5>
        </div>

        <div className={styles.subscription_badge_grey}>
          <h5> 42:32:12</h5>
        </div>
      </LinkFactory>
    </div>
  );
};

export default SubscriptionBanner;
