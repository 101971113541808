import { ButtonFactory } from "@shared/ui/Button";
import styles from "./styles.module.css";

import CloseIcon from "@assets/close.svg";
import { useState } from "react";

interface IProps {
  onClose: () => void;
}

const HowToUsePopup = ({ onClose }: IProps) => {
  const [step, setStep] = useState<string>("first");

  const stepFirst = (
    <div>
      <div className={styles.how_to_use_content}>
        <h5>How to Prompt for Images</h5>
        <h5>Start your request using one of these phrases:</h5>
        <ul className={styles.how_to_use_list}>
          <li>Send me...</li>
          <li>Show me...</li>
          <li>Can I see...</li>
          <li>Send...</li>
        </ul>
      </div>
      <div className={styles.how_to_use_divider}></div>
      <div className={styles.how_to_use_pagination}>
        <h5>Step 1 of 2</h5>
        <ButtonFactory
          variant="danger"
          label="Next"
          type="button"
          onClick={() => setStep("second")}
        />
      </div>
    </div>
  );

  const stepSecond = (
    <div>
      <div className={styles.how_to_use_content}>
        <h5>Complete the sentence by specifying what you'd like to see.</h5>
        <h5>Examples:</h5>
        <ul className={styles.how_to_use_list}>
          <li>"Send me a selfie"</li>
          <li>"Show me your face"</li>
          <li>"Can I see a picture of you"</li>
        </ul>
      </div>
      <div className={styles.how_to_use_divider}></div>
      <div className={styles.how_to_use_pagination}>
        <h5>Step 2 of 2</h5>
        <div className={styles.how_to_use_actions}>
          <ButtonFactory
            variant="primary"
            label="Prev"
            type="button"
            onClick={() => setStep("first")}
          />
          <ButtonFactory
            variant="danger"
            label="Got It!"
            type="button"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.how_to_use_container}>
      <div className={styles.how_to_use_wrapper}>
        <img
          src={CloseIcon}
          className={styles.how_to_user_close_button}
          alt="close"
          onClick={onClose}
        />
        <h6 className={styles.how_to_use_header}>How to use</h6>
        <div className={styles.how_to_use_divider}></div>
        {step === "first" && stepFirst}
        {step === "second" && stepSecond}
      </div>
    </div>
  );
};

export default HowToUsePopup;
