import styles from "./styles.module.css";

interface IProps {
  className?: string
}

const Benefits = ({className = ""}: IProps) => {
  return (
    <div className={`${styles.benefits_container} ${className}`}>
      <div className={styles.benefits_content}>
        <h4>Premium Benefits</h4>
        <ul>
          <li>Unlimited Text Messages</li>
          <li>Get 100 FREE Tokens / Month</li>
          <li>Remove image blur </li>
          <li>AI image generation</li>
        </ul>
      </div>
    </div>
  );
};

export default Benefits;
