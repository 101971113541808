import styles from './styles.module.css';
import { IButtonTransfer } from '@shared/interfaces';

interface IProps {
  label: string,
  children?:  React.ReactNode,
  value?: string,
  onClick?: (event: IButtonTransfer) => void;
}

const SecondaryButton = ({
  label,
  onClick,
  children,
  value = "default_btn",
}: IProps) => {
  if (children) {
    return (
      <button 
        onClick={()=> onClick && onClick({ type: value })}
        className={`${styles.secondary_btn} ${styles.secondary_btn_md}`}
    >
     {children}
    </button>
    );
  }

  return (
    <button 
      onClick={() => onClick && onClick({type: 'primary'})}
    >
      {label}
    </button>
  );
}

export default SecondaryButton;