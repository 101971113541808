import { ModelMessageIcon } from "./ui/ModelMessageIcon";
import { ModelMessageText } from "./ui/ModelMessageText";

interface IProps {
  variant?: "text" | "icon" | "img";
  type?: string;
  text?: string;
  icon?: React.ReactElement;
  img?: string;
  id?: string;
}

const MessageItemFactory = ({
  variant = "text",
  type,
  text = "",
  icon = <></>,
  ...props
}: IProps) => {
  switch (variant) {
    case "icon":
      return <ModelMessageIcon type={type} icon={icon} />;

    case "img":
      return <></>;

    case "text":
    default:
      return <ModelMessageText type={type} text={text} {...props} />;
  }
};

export default MessageItemFactory;
