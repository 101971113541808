import { InputItem } from "@shared/ui/Inputs";
import styles from "./styles.module.css";

import Arrow from "@assets/typing_arrow.svg";
import { ButtonFactory } from "@shared/ui/Button";
import { IFormMessageState } from "@widgets/auth/loginForm";
import { AskMe } from "../ask";

interface IProps {
  showHowToUse: () => void;
  onSendMessage: (props: string) => void;
  onChangeInput: (e: any) => void;
  formData: IFormMessageState;
  onSubmitForm: (e: React.FormEvent) => void;
  disableSendBtn: boolean;
}

const Typing: React.FC<IProps> = ({
  showHowToUse,
  onSendMessage,
  onChangeInput,
  formData,
  onSubmitForm,
  disableSendBtn,
}: IProps) => {

  const defineVariant = () => {
    if (formData.message.length) {
      return "danger";
    }

    return "secondary";
  };

  const defineDisableBtn = () => {
    if (disableSendBtn) {
      return `${styles.typing_container} ${styles.typing_container_disabled}`
    }

    return styles.typing_container
  }

  return (
    <form className={defineDisableBtn()} onSubmit={onSubmitForm}>
      <InputItem
        type="text"
        name="message"
        onChange={onChangeInput}
        value={formData.message}
        placeholder="Type message"
      />
      <ButtonFactory
        label=""
        type="submit"
        variant={defineVariant()}
        children={<img src={Arrow} alt="submit_btn" />}
      />
      <div className={styles.typing_askme}>
        <AskMe
          showHowToUse={showHowToUse}
          onChangeInput={(message: string) =>
            onChangeInput({ target: { name: "message", value: message } })
          }
        />
      </div>
    </form>
  );
};

export default Typing;
