import { IValidationDataTransfer } from "@shared/interfaces";
import { Validation } from "@shared/libs";
import { IFormMessageState } from "@widgets/auth/loginForm/model/login.interfaces";


const onTypingFormValid = <T,>(formData: IFormMessageState) => {
  let errorsState = {};

  const validationEmail = new Validation<
  IValidationDataTransfer
  >({
    field: formData.message,
    title: 'message',
  })
    .isRequired()
    .maxLength(64)
    .getErrors();

  if (validationEmail.length) {
    errorsState = {
      ...errorsState,
      email: validationEmail[0],
    };
  }

  return errorsState;
};

export default onTypingFormValid;