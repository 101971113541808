import DangerButton from "@shared/ui/Button/ui/Danger/DangerButton";
import DefaultButton from "./ui/Default/DefaultButton";
import PrimaryButton from "./ui/Primary/PrimaryButton";
import { IButtonTransfer } from "@shared/interfaces";
import SecondaryButton from "./ui/Secondary/SecondaryButton";

interface IProps {
  //variant?: 'text' | 'contained' | 'outlined',
  variant?: string;
  label: string;
  type: string;
  children?: React.ReactNode;
  value?: string;
  onClick?: (event: IButtonTransfer) => void;
}

const ButtonFactory = ({ variant, label, ...props }: IProps) => {
  switch (variant) {
    case "primary":
      return <PrimaryButton label={label} {...props} />;
    case "secondary":
      return <SecondaryButton label={label} {...props} />;
    case "danger":
      return <DangerButton label={label} {...props} />;

    default:
      return <DefaultButton label={label} {...props} />;
  }
};

export default ButtonFactory;
