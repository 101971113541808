import { Search } from "@shared/ui/Search";
import styles from "./styles.module.css";
import { useSearchParams } from "react-router-dom";
import { ButtonFactory } from "@shared/ui/Button";
import { IButtonTransfer } from "@shared/interfaces";
import { useState } from "react";

const FiltersBar: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const FILTERS = [
    {
      name: "All characters",
      value: "all_characters",
    },
    {
      name: "Leils",
      value: "Leils",
      count: 4,
    },
    {
      name: "Torie",
      value: "Torie",
      count: 10,
    },
    {
      name: "Alexa",
      value: "Alexa",
      count: 2,
    },
  ];

  const defineActiveFilter = (value: string) => {
    const activeSearchParams = Object.fromEntries(searchParams.entries());
    const filterBy = activeSearchParams?.filter_by;

    if (filterBy === value || (!filterBy && value === "all_characters")) {
      return "danger";
    }
    return "primary";
  };

  const renderFilters = () => {
    return FILTERS.map((filter) => (
      <ButtonFactory
        key={filter.name}
        variant={defineActiveFilter(filter.value)}
        label=""
        type="button"
        value={filter.value}
        onClick={onFilterClick}
        children={
          <h5 className={`danger_btn-${defineActiveFilter(filter.value)}`}>
            {filter.name} {filter.count && <span>({filter.count})</span>}
          </h5>
        }
      />
    ));
  };

  const onFilterClick = (e: IButtonTransfer) => {
    searchParams.set("filter_by", e.type);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.filter_bar_container}>
      <h4>Character Filter</h4>
      <div className={styles.filter_bar_search_container}>
        <Search
          value={searchValue}
          onChange={onSearchChange}
          placeholder="Search"
        />
      </div>
      <div className={styles.filter_bar_wrapper}>{renderFilters()}</div>
    </div>
  );
};

export default FiltersBar;
