
import styles from "./styles.module.css";
import { Benefits } from "@widgets/plans";

const Event = () => {
  return (
    <div className={styles.subscribe_event_text}>
      <h3>
        <span>Get Exclusive </span>
        Discount Only Today!
      </h3>
      <h4>
        Up to <span>75%</span> off for first subscription!
      </h4>
      <Benefits className={styles.subscribe_event_benefits}/>
    </div>
  );
};

export default Event;
