const { REACT_APP_WS_URL } = process.env;
const PING_PONGINTERVAL = 30000;

export class Socket {
  socket;
  interval;

  constructor(chatId) {
    if (typeof Socket.instance === 'object') {
      return Socket.instance;
    }

    Socket.instance = this;

    return this;
  }

  send(event, data = {}) {
    this.socket.send(JSON.stringify({ event, data }));
  }

  initListeners(chatId, callback, messageCallback) {

    const token = localStorage.getItem('_accessToken');

    this.id = chatId;

    this.socket = new WebSocket(
     `${REACT_APP_WS_URL}${chatId}/messages?token=${token}`,
    );

    const setPingPongConnection = () => {
      console.log(`[WSS] id=${this.id} start ping-pong connection`);

      window.intervalID = setInterval(() => {
        this.socket.send('ping');
      }, PING_PONGINTERVAL);
    };

    const mapingToWindow = () => {
      window.socket = this.socket;
    }

    const close = (e) => {
      console.log(
        'Socket is closed.',
        e.reason
      );
    };

    this.socket.onmessage = (message) => {
      const gettingMessage = message.data;

      if (gettingMessage === "pong") {
        return;
      }
      
      messageCallback(JSON.parse(gettingMessage));
    }

    this.socket.onopen = () => {
      window.interval = null;
      clearInterval(window.interval);
      console.log(`[WSS] id=${this.id} connected`);

      setPingPongConnection();
      callback();
      mapingToWindow();
    };

    this.socket.onclose = (e) => {
      close(e);
    };
    
    this.socket.onerror = (e) => {
      console.log("Error Socket connection", e);
      window.interval = setTimeout(() => {
        this.initListeners(callback);
      }, 2000);
    };
  }
}
