import styles from './styles.module.css';

import { ButtonFactory } from "@shared/ui/Button";
import { InputItem } from '@shared/ui/Inputs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFormState } from '../model';
import useForm from '@shared/model/hooks/useForm';
import { onLoginFormValid } from '../model';
import { IButtonTransfer, ROUTES, ROUTES_QUERY } from '@shared/interfaces';
import { SocialDiscord, SocialGoogle, SocialMeta } from '@features/auth/social';
import { TextSignIn } from '@features/auth/textSignIn';

import AuthImg from "@assets/auth_img.jpg";
import Eye from "@assets/eye.svg";
import EyeAcross from "@assets/eye_across.svg";
import CloseIcon from "@assets/close.svg";
import { useUserAuth } from '@shared/model';

const CreateAccountForm = () => {
  const navigate = useNavigate();
  const { signUp } = useUserAuth();
  const [isPasswordShow, setPasswordShow] = useState(false);
  const inputType = isPasswordShow ? 'text' : 'password';
  const eyeIcon = isPasswordShow?Eye: EyeAcross;

  const [activeFormError, setActiveFormError] = useState<Record<string, string>>()

  const user: IFormState = {
    email: '',
    password: '',
  };

  const { 
    formData, 
    onChangeInput, 
    onSubmitForm,
    resetFormData,
  } = useForm<IFormState>(
    user,
    handlerBeforeSubmitForm
  );

  async function handlerBeforeSubmitForm() {
    const formErrors = onLoginFormValid<IFormState>(formData);
    const arrayOfErrors = Object.keys(formErrors);

    if (arrayOfErrors.length) {
      return setActiveFormError(formErrors);
    }

    setActiveFormError({});
  
    try{
      await signUp(formData);
      resetFormData();
    } catch(e: any){
      setActiveFormError({email: e.message});
    }
  };

  const changeVisible = () => {
    setPasswordShow(!isPasswordShow);
  };

  const onHandleSocialGoogleLogin = (event: IButtonTransfer) => {
    console.log("===>", event)
  };

  const defineErrorType = (value: string) => {
    if (!activeFormError) {
      return;
    }

    const firstError = Object.keys(activeFormError)[0];

    if (!firstError || firstError !== value) {
      return null;
    }
    
    return activeFormError[value];
  };

  const onClose = () => {
    const backAuthUrl = localStorage.getItem('backAuthUrl');

    if (!backAuthUrl) {
      navigate(`${ROUTES.HOME}?type=${ROUTES_QUERY.female}`);
    }
  };

  return (
    <div className={styles.form_singup_container}>
      <div className={styles.form_signup_img}>
        <img src={AuthImg} alt="auth_img"/>
      </div>
      <div className={styles.form_signup_wrapper}>
        <img 
          src={CloseIcon}
          className={styles.close_button}
          alt="close"
          onClick={onClose}
        />
        <form onSubmit={onSubmitForm} className={styles.auth_form}>
          <h2 className={styles.form_auth_title}>Create <span>Account</span></h2>
          <div className={styles.form_input_wrapper}>
            <InputItem
              type="email"
              name= "email"
              onChange={onChangeInput}
              value={formData?.email || ''}
              placeholder = "Email Address"
              error={defineErrorType('email')}
            />
          </div>
          <InputItem
            name= "password"
            type= {inputType}
            iconRight={eyeIcon}
            onClick={changeVisible}
            placeholder = "Password"
            onChange={onChangeInput}
            value={formData?.password || ''}
            error={defineErrorType('password')}
          />
          <div className={styles.error_box}>
            {activeFormError && 
            <span className={styles.form_error}>
              {activeFormError.email || activeFormError.password}
            </span>}
          </div>
            
          <ButtonFactory 
            type="submit"
            label="Sign in" 
            variant='danger'
          />

          <div className={styles.divider}>
            <div className={styles.divider_line}></div>
            <h5>or</h5>
          </div>
        </form>
        <ButtonFactory
            label="" 
            type="button"
            value="social_google"
            children={<SocialGoogle />}
            onClick={onHandleSocialGoogleLogin}
        />
        <div className={styles.social_container}>
          <ButtonFactory
            label="" 
            type="button"
            variant='primary'
            value="social_discord"
            children={<SocialDiscord />}
            onClick={onHandleSocialGoogleLogin}
        />

          <ButtonFactory
            label="" 
            type="button"
            value="social_meta"
            children={<SocialMeta />}
            onClick={onHandleSocialGoogleLogin}
        />      
        </div>
        <div className={styles.signup_text}>
          <TextSignIn />
        </div>
      </div>
    </div>
  )
}

export default CreateAccountForm;