import styles from "./styles.module.css";

import SearchIcon from "@assets/search.svg";
import CloseIcon from "@assets/close.svg";

interface IProps {
  value: string;
  onChange: (payload: string) => void;
  placeholder?: string;
}

const Search: React.FC<IProps> = ({ onChange, value, placeholder }: IProps) => {
  const resetSearch = () => {
    onChange("");
  };
  return (
    <div className={styles.search_container}>
      <img alt="search" src={SearchIcon} className={styles.search_icon} />
      <input
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value)
        }
        placeholder={placeholder || "Search for a profile..."}
      />
      {value && (
        <img
          alt="close"
          src={CloseIcon}
          className={styles.close_icon}
          onClick={resetSearch}
        />
      )}
    </div>
  );
};

export default Search;
