import styles from "./styles-fee.module.css";

const ProfileFee = () => {
  return (
    <div className={styles.profile_fee_container}>
      <div className={styles.profile_fee_plan}>
        <h5>
          Current Plan
          <span>Free</span>
        </h5>
        <button
          onClick={() => {}}
          className={`${styles.default_btn} ${styles.default_btn_md}`}
        >
          Change Plan
        </button>
      </div>
      <div className={styles.profile_fee_sub}>
        <div className={styles.profile_fee_sub_item}>
          <h6>
            Payment date: <span>26.06.24</span>
          </h6>
        </div>
        <div className={styles.profile_fee_sub_item}>
          <h6>
            Subscription to: <span>26.06.24</span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ProfileFee;
