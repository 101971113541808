import { IFormEmailState, IFormState } from "@widgets/auth/loginForm";
import { auth } from "app/host/config/firebase";
import {
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  TwitterAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { IUserState, useUserStore } from "@entities/user";

const context = {
  isLoading: false,
  logIn: (formData: IFormState) => {},
  signUp: (formData: IFormState) => {},
  restorePasswordByEmail: (formData: IFormEmailState) => {},
  loginSocialGoogle: () => {},
  loginSocialTwitter: () => {},
  logOut: () => {},
};

const UserAuthContext = createContext(context);

export const useUserAuth = () => {
  return useContext(UserAuthContext);
};

interface IProps {
  children: React.ReactElement;
}

export const UserAuthContextProvider = ({ children }: IProps) => {
  const [isLoading] = useState(true);
  const setUserToStore = useUserStore((state: IUserState) => state.setUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser: any) => {
      console.log("Auth", currentUser);
      await setUserToStore(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, [setUserToStore]);

  const logIn = (formData: IFormState) => {
    return signInWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    ).catch((error) => {
      if (error.code === "auth/invalid-credential") {
        throw new Error("Wrong email or password.");
      }
    });
  };

  const signUp = (formData: IFormState) => {
    return createUserWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    ).catch((error) => {
      if (error.code === "auth/email-already-in-use") {
        throw new Error("Email already exist.");
      }
    });
  };

  const restorePasswordByEmail = (formData: IFormEmailState) => {
    return sendPasswordResetEmail(auth, formData.email);
  };

  const logOut = () => {
    return signOut(auth);
  };

  const loginSocialGoogle = () => {
    const googleAuthProvider = new GoogleAuthProvider();

    return signInWithPopup(auth, googleAuthProvider);
  };

  const loginSocialTwitter = () => {
    const twitterAuthProvider = new TwitterAuthProvider();

    return signInWithPopup(auth, twitterAuthProvider);
  };

  const context = {
    isLoading,
    logIn,
    signUp,
    logOut,
    loginSocialGoogle,
    loginSocialTwitter,
    restorePasswordByEmail,
  };

  return (
    <UserAuthContext.Provider value={context}>
      {children}
    </UserAuthContext.Provider>
  );
};
