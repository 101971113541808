import { DropDownMenu } from "@shared/ui/DropDown";
import { useState } from "react";

import styles from "./styles-header-menu-dd.module.css";
import Token from "@assets/token.svg";
import Plus from "@assets/plus.svg";
import PlusBlack from "@assets/plus_oval_black.svg";

import { LinkFactory } from "@shared/ui/Link";
import { ROUTES } from "@shared/interfaces";

const TokenMenuDropDown = () => {
  const [isDropDownShow, setDropDownShow] = useState(false);

  document.body.className = isDropDownShow ? "token_menu_active" : "";

  const HEADER_MENU_LIST: any[] = [
    {
      title: "Image cost 0.2",
      type: "element",
      children: (
        <div className={styles.header_token_menu_item}>
          <h5>
            Image cost <span>0.2</span>
          </h5>
          <img src={Token} alt="cost_element" />
        </div>
      ),
    },
    {
      title: "Can I see...",
      type: "element",
      children: (
        <LinkFactory label="" type="danger" path={ROUTES.SUBSCRIBE}>
          <div className={styles.header_token_menu_item_bye}>
            <h5>Buy more</h5>
            <img src={PlusBlack} alt="plus_black" />
          </div>
        </LinkFactory>
      ),
    },
  ];

  const onClick = ({ type }: { type: string }) => {
    console.log(type);
  };

  const onVisibleChange = () => {
    setDropDownShow(!isDropDownShow);
  };

  const children = (
    <div className={styles.header_token_container}>
      <img src={Token} alt="header_token" />
      <h5>
        Tokens <span>129.6</span>
      </h5>
      <img src={Plus} alt="header_plus" />
    </div>
  );

  return (
    <DropDownMenu
      active={""}
      isVisible={isDropDownShow}
      menuList={HEADER_MENU_LIST}
      callBack={onClick}
      onVisibleChange={onVisibleChange}
    >
      {children}
    </DropDownMenu>
  );
};

export default TokenMenuDropDown;
