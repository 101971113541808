import {
  ChatPage,
  ForgotPasswordPage,
  GalleryPage,
  HomePage,
  LoginPage,
  NotFound,
  RegisterPage,
  SubscribePage,
  PlansPage,
} from "@pages/index";
import { SettingsPage } from "@pages/settings";
import { IRoute, ROUTES } from "@shared/interfaces";
import { Navigate } from "react-router-dom";

export class RouterBuilder {
  baseRouters: IRoute[] = [];
  authRouter: IRoute[] = [];

  constructor() {
    this.baseRouters = [
      {
        path: ROUTES.BASE,
        element: <HomePage />,
      },
      {
        path: ROUTES.HOME,
        element: <HomePage />,
      },
      {
        path: ROUTES.SETTINGS,
        element: <SettingsPage />,
      },
      {
        path: ROUTES.LOGIN,
        element: <Navigate to={ROUTES.HOME} />,
      },
      {
        path: ROUTES.REGISTER,
        element: <Navigate to={ROUTES.HOME} />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <Navigate to={ROUTES.HOME} />,
      },
      {
        path: ROUTES.GALLERY,
        element: <GalleryPage />,
      },
      {
        path: ROUTES.CHAT,
        element: <ChatPage />,
      },
      {
        path: ROUTES.CHAT_ID,
        element: <ChatPage />,
      },
      {
        path: ROUTES.SUBSCRIBE,
        element: <SubscribePage />,
      },

      {
        path: ROUTES.PLANS,
        element: <PlansPage />,
      },

      {
        path: ROUTES.NOT_FOUND,
        element: <NotFound />,
      },
    ];

    this.authRouter = [
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTES.REGISTER,
        element: <RegisterPage />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ForgotPasswordPage />,
      },
    ];
  }

  addAuthRouters(): RouterBuilder {
    const routesWithoutAuth = this.baseRouters.filter(
      (route) =>
        route.path !== ROUTES.LOGIN &&
        route.path !== ROUTES.REGISTER &&
        route.path !== ROUTES.RESET_PASSWORD &&
        route.path !== ROUTES.SETTINGS
    );

    this.baseRouters = [...routesWithoutAuth, ...this.authRouter];

    return this;
  }

  removeAuthRouters(): RouterBuilder {
    this.baseRouters.filter(({ path }: IRoute) => {
      return path !== ROUTES.LOGIN && path !== ROUTES.REGISTER;
    });

    return this;
  }

  build(): IRoute[] {
    return this.baseRouters;
  }
}
