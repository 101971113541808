import { Profile, ProfileFee } from "@widgets/profile";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";

const SettingsPage = () => {
  return (
    <div className={styles.subscribe_container}>
      <AsideStructure>
        <div className={styles.settings_container}>
          <h1 className={styles.settings_title}>
            Profile <span>Settings</span>
          </h1>
          <div className={styles.settings_content}>
            <Profile />
          </div>

          <div className={styles.settings_content}>
            <ProfileFee />
          </div>
        </div>
      </AsideStructure>
    </div>
  );
};

export default SettingsPage;
