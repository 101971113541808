import { useState } from "react";
import styles from "./styles.module.css";
import { BonusItem } from "@features/subscription";
import { ButtonFactory } from "@shared/ui/Button";

const BONUSES = [
  {
    id: 1,
    price: "$9.99",
    amount: 200,
    bonusesPercentage: 200,
  },
  {
    id: 2,
    price: "$10.99",
    amount: 750,
    bonusesPercentage: 210,
  },
  {
    id: 3,
    price: "$10.99",
    amount: 1100,
    bonusesPercentage: 210,
  },
  {
    id: 4,
    price: "$10.99",
    amount: 2300,
    bonusesPercentage: 210,
  },
  {
    id: 5,
    price: "$10.99",
    amount: 4800,
    bonusesPercentage: 210,
  },
  {
    id: 6,
    price: "$10.99",
    amount: 7500,
    bonusesPercentage: 210,
  },
];

const Bonuses = () => {
  const [activeBonus, setActiveBonus] = useState<number>(1);

  const payWithCredit = () => {
    console.log(
      "payWithCredit bonus id ->",
      BONUSES.find((item) => item.id === activeBonus)
    );
  };

  const payWithCrypto = () => {
    console.log(
      "payWithCrypto bonus id ->",
      BONUSES.find((item) => item.id === activeBonus)
    );
  };

  return (
    <div className={styles.subscribe_bonuses}>
      {BONUSES.map((bonusItem) => (
        <BonusItem
          key={bonusItem.id}
          bonus={bonusItem}
          isActive={bonusItem.id === activeBonus}
          setActiveBonus={(id: number) => setActiveBonus(id)}
        />
      ))}
      <div className={styles.subscribe_actions}>
        <ButtonFactory
          variant="danger"
          label="Pay with Credit / Debit Card"
          type="button"
          onClick={payWithCredit}
        />

        <ButtonFactory
          variant="primary"
          label="Pay with crypto"
          type="button"
          onClick={payWithCrypto}
        />
      </div>
    </div>
  );
};

export default Bonuses;
