import { DropDownMenu } from "@shared/ui/DropDown";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";

import Filter from "@assets/filter.svg";
import FilterActive from "@assets/filter_active.svg";
import CheckActive from "@assets/check_active.svg";
import { useSearchParams } from "react-router-dom";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { url } from "@shared/libs";

export const HEADER_MENU_LIST: any[] = [
  {
    title: "Earliest",
    value: "earliest",
    icon: CheckActive,
  },
  {
    title: "Latest",
    value: "latest",
    icon: CheckActive,
    activeKey: true,
  },
];

const GalleryHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDropDownShow, setDropDownShow] = useState(false);
  const { fetchGallery, setModels } = useModelStore(
    (state: IModelState) => state
  );

  document.body.className = isDropDownShow ? "gallery_filter_active" : "";

  const activeSearchParams = Object.fromEntries(searchParams.entries());
  const isSorted = activeSearchParams?.sort_by;
  const isType = activeSearchParams?.type;
  const SortedIcon = isSorted ? FilterActive : Filter;

  useEffect(() => {
    if (!isDropDownShow) {
      const searchParams = isType
        ? `?${url.generateQueryString(activeSearchParams)}`
        : "?type=female";

      const fetchAPI = async () => {
        await fetchGallery(searchParams);
      };
      fetchAPI();
    }

    return () => {
      setModels([]);
    };
  }, [isSorted]);

  const onClick = ({ type }: { type: string }) => {
    searchParams.set("sort_by", type);
    setSearchParams(searchParams);

    setDropDownShow(false);
  };

  const defineActice = () => {
    return activeSearchParams?.sort_by || "";
  };

  const children = (
    <div
      onClick={() => setDropDownShow(!isDropDownShow)}
      className={styles.gallery_drop_down_content}
    >
      <h5>Sort By</h5>
      <div className={styles.gallery_content_img}>
        <img src={SortedIcon} alt="gallery_filter" />
      </div>
    </div>
  );

  return (
    <div className={styles.gallery_header_container}>
      <h4>Gallery</h4>
      <div>
        <DropDownMenu
          active={defineActice()}
          isVisible={isDropDownShow}
          menuList={HEADER_MENU_LIST}
          callBack={onClick}
          onVisibleChange={() => setDropDownShow(!isDropDownShow)}
        >
          {children}
        </DropDownMenu>
      </div>
    </div>
  );
};

export default GalleryHeader;
