import styles from "./styles.module.css";

const Event = () => {
  return (
    <div className={styles.subscribe_event_text}>
      <h3>
        <span>Olympics Games Sale </span> for Premium Users
      </h3>
      <h4>Discount ends soon. Don't miss</h4>
      <h4>out!</h4>
    </div>
  );
};

export default Event;
