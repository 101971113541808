import styles from "./styles.module.css";
import { ChatIcon } from "@shared/ui/ChatIcon";
import { IModel, ROUTES } from "@shared/interfaces";
import { LinkFactory } from "@shared/ui/Link";

interface IProps {
  model: IModel;
}

const ModelCardFullMeta: React.FC<IProps> = ({ model }: IProps) => {
  return (
    <div className={styles.model_container}>
      <LinkFactory
        label=""
        path={`${ROUTES.CHAT}/${model.id}`}
        className={[styles.link_typeof_div]}
      >
        <div className={styles.model_wrapper}>
          <img src={model.photo} alt={`model-${model.id}`} />
        </div>
        <div className={styles.model_info}>
          <div className={styles.model_info_main}>
            <h4>
              {model.name}
              <span>, {model.age} years</span>
            </h4>
          </div>
          <h6 className={styles.model_personality}>{model.description}</h6>
        </div>
        <div className={styles.model_chat_icon}>
          <ChatIcon />
        </div>
      </LinkFactory>
    </div>
  );
};

export default ModelCardFullMeta;
