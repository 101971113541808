import styles from "./styles.module.css";

import Token from "@assets/token.svg";

interface IProps {
  bonus: any;
  isActive: boolean;
  setActiveBonus: (id: number) => void;
}

const BonusItem: React.FC<IProps> = ({
  bonus,
  isActive,
  setActiveBonus,
}: IProps) => {
  const classes = [styles.bonus_container];

  if (isActive) {
    classes.push(styles.bonus_active);
  }

  return (
    <div className={classes.join(" ")} onClick={() => setActiveBonus(bonus.id)}>
      <h6 className={styles.bonus_percentage}>
        +{bonus.bonusesPercentage}% bonus
      </h6>
      <div className={styles.bonus_descr}>
        <img src={Token} alt="token" />
        <h3>{bonus.amount}</h3>
      </div>
      <h5 className={styles.bonus_price}>{bonus.price}</h5>
    </div>
  );
};

export default BonusItem;
