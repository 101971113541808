import styles from './styles.module.css';
import { IButtonTransfer } from '@shared/interfaces';
interface IProps {
  label: string,
  children?:  React.ReactNode,
  value?: string,
  onClick?: (event: IButtonTransfer) => void;
}

const DefaultButton = ({
  label,
  onClick,
  children,
  value = "default_btn",
}: IProps) => {
  if (children) {
    return (
      <button 
        onClick={()=> onClick && onClick({type: value})}
        className={`${styles.default_btn} ${styles.default_btn_md}`}
      >
        {children}
      </button>
    );
  }

  return (
    <button 
      onClick={()=> onClick && onClick({type: 'default'})}
      className={`${styles.default_btn} ${styles.default_btn_md}`}
    >
      {label}
    </button>
  );
}

export default DefaultButton;