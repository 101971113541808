import { Search } from "@shared/ui/Search";
import styles from "./styles.module.css";
import { useSearchParams } from "react-router-dom";
import { ButtonFactory } from "@shared/ui/Button";
import { IButtonTransfer } from "@shared/interfaces";
import { useState } from "react";
import Arrow from "@assets/arrow.svg";

const FiltersBarActive: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDropDownVisible, setDropDownVisible] = useState(false);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const FILTERS = [
    {
      name: "All characters",
      value: "all_characters",
    },
    {
      name: "Leils",
      value: "Leils",
      count: 4,
    },
    {
      name: "Torie",
      value: "Torie",
      count: 10,
    },
    {
      name: "Alexa",
      value: "Alexa",
      count: 2,
    },
  ];

  const defineActiveFilter = (value: string) => {
    const activeSearchParams = Object.fromEntries(searchParams.entries());
    const filterBy = activeSearchParams?.filter_by;

    if (filterBy === value || (!filterBy && value === "all_characters")) {
      return "danger";
    }

    return "primary";
  };

  const renderFilters = () => {
    return FILTERS.map((filter) => (
      <ButtonFactory
        key={filter.name}
        variant={defineActiveFilter(filter.value)}
        label=""
        type="button"
        value={filter.value}
        onClick={onFilterClick}
        children={
          <h5 className={`danger_btn-${defineActiveFilter(filter.value)}`}>
            {filter.name} {filter.count && <span>({filter.count})</span>}
          </h5>
        }
      />
    ));
  };

  const onFilterClick = (e: IButtonTransfer) => {
    searchParams.set("filter_by", e.type);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.filter_bar_active_container}>
      <div
        className={styles.filter_bar_active_header}
        onClick={() => setDropDownVisible(!isDropDownVisible)}
      >
        <h4>Character Filter</h4>
        <div
          className={`${styles.header_action_content_arrow} ${
            isDropDownVisible && styles.header_action_content_arrow_revert
          }`}
        >
          <img src={Arrow} alt="user_profile_arrow" />
        </div>
      </div>

      {isDropDownVisible && (
        <div className={styles.filter_bar_search_container}>
          <Search
            value={searchValue}
            onChange={onSearchChange}
            placeholder="Search"
          />
        </div>
      )}

      {isDropDownVisible && (
        <div className={styles.filter_bar_wrapper}>{renderFilters()}</div>
      )}
    </div>
  );
};

export default FiltersBarActive;
