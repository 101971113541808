import { DropDownMenu } from "@shared/ui/DropDown";
import styles from "./styles.module.css";
import { useState } from "react";
import MenuTrash from "@assets/trash_red.svg";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";

const MENU_LIST: any[] = [
  {
    title: "Delete",
    value: "delete_message",
    icon: MenuTrash,
  },
];

interface IProps {
  text: string;
  type?: any;
  id?: string;
}

const ModelMessageText = ({ text, type, id }: IProps) => {
  const [isDropDownShow, setDropDownShow] = useState(false);
  const { deleteMessage } = useModelStore((state: IModelState) => state);
  const classes = [styles.message_wrapper];

  // insted of ? : becouse internal component
  if (isDropDownShow) {
    document.body.className = "chat_delete_message";
  }

  if (type === "model") {
    classes.push(styles.message_model);
  }

  if (type === "user") {
    classes.push(styles.message_self);
  }

  const parseText = (text: string) => {
    if (!text) {
      return text;
    }

    return text
      .replaceAll("<|eot_id|>", "")
      .replaceAll(
        "*smiles shyly*",
        String.fromCodePoint(parseInt("0x1F60A", 16))
      )
      .replaceAll("*smiles*", String.fromCodePoint(parseInt("0x1F600", 16)))
      .replaceAll("*blushes*", String.fromCodePoint(parseInt("0x1F970", 16)))
      .replaceAll("*waves*", String.fromCodePoint(parseInt("0x1F917", 16)))
      .replaceAll("*laughs*", String.fromCodePoint(parseInt("0x1F606", 16)))
      .replaceAll("*giggles*", String.fromCodePoint(parseInt(" 0x1F604", 16)))
      .replaceAll("*smirks*", String.fromCodePoint(parseInt(" 0x1F600", 16)))
      .replaceAll(
        "*smiles confidently*",
        String.fromCodePoint(parseInt("0x1F602", 16))
      );
  };

  const onClick = async ({ type }: { type: string }) => {
    setDropDownShow(false);
    if (id) {
      return await deleteMessage(id);
    }

    console.error("Message id not defined");
  };

  const renderMessage = () => {
    const message = (
      <div className={classes.join(" ")}>
        <div className={styles.message_container}>
          <div>
            <h5>{parseText(text || "")}</h5>
          </div>
        </div>
      </div>
    );

    if (type === "model") {
      return message;
    }

    return (
      <DropDownMenu
        active=""
        isVisible={isDropDownShow}
        menuList={MENU_LIST}
        callBack={onClick}
        onVisibleChange={() => setDropDownShow(!isDropDownShow)}
      >
        {message}
      </DropDownMenu>
    );
  };

  return renderMessage();
};

export default ModelMessageText;
