import { LinkFactory } from "@shared/ui/Link";
import styles from "./styles.module.css";
import { IModel, ROUTES } from "@shared/interfaces";
import { IUserState, useUserStore } from "@entities/user";

interface IProps {
  model: IModel;
}

const ModelCard: React.FC<IProps> = ({ model }: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);

  const classes = [styles.model_wrapper];
  if (!user.plan) {
    classes.push(styles.model_container_blur);
  }

  return (
    <div className={styles.model_container}>
      <LinkFactory
        label=""
        path={`${ROUTES.GALLERY}?preview=${model.id}`}
        className={[styles.link_typeof_div]}
      >
        <div className={classes.join(" ")}>
          <img src={model.photo} alt={`model-${model.id}`} />
        </div>
      </LinkFactory>
    </div>
  );
};

export default ModelCard;
